// Copyright 2020-2024 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

import AlertGroup from "components/AlertGroup";
import Row from "components/Row";
import { InstalledPackageSummary } from "gen/kubeappsapis/core/packages/v1alpha1/packages_pb";
import { Link } from "react-router-dom";
import { IClusterServiceVersion, IResource } from "shared/types";
import * as url from "shared/url";
import { escapeRegExp } from "shared/utils";
import "./AppList.css";
import AppListItem from "./AppListItem";
import CustomResourceListItem from "./CustomResourceListItem";

export interface IAppListProps {
  appList: InstalledPackageSummary[] | undefined;
  cluster: string;
  namespace: string;
  filter: string;
  customResources: IResource[];
  csvs: IClusterServiceVersion[];
}

function AppListGrid(props: IAppListProps) {
  const { appList, customResources, cluster, namespace, filter } = props;
  const filteredReleases = (appList || []).filter(a =>
    new RegExp(escapeRegExp(filter), "i").test(a.name),
  );
  const filteredCRs = customResources.filter(cr =>
    new RegExp(escapeRegExp(filter), "i").test(cr.metadata.name),
  );

  if (filteredReleases.length === 0 && filteredCRs.length === 0) {
    return (
      <div className="applist-empty">
        <AlertGroup status="info">
          Deploy applications on your Kubernetes cluster with a single click.
        </AlertGroup>
        <h2>Welcome</h2>
        <p>
          Start browsing your <Link to={url.app.catalog(cluster, namespace)}>favourite apps</Link>{" "}
          or check the{" "}
          <a href={"https://cozystack.io/docs/"} target="_blank" rel="noopener noreferrer">
            documentation
          </a>
          .
        </p>
      </div>
    );
  }
  return (
    <Row>
      <>
        {filteredReleases.map(r => {
          return (
            <AppListItem
              key={`${r.installedPackageRef?.context?.namespace}-${r.installedPackageRef?.identifier}`}
              app={r}
              cluster={cluster}
            />
          );
        })}

        {filteredCRs.map(r => {
          /* eslint-disable prettier/prettier */
          const csv = props.csvs.find(c =>
            c.spec.customresourcedefinitions.owned?.some(crd => crd.kind === r.kind),
          );
          return (
            <CustomResourceListItem
              cluster={cluster}
              key={r.metadata.name + "_" + r.metadata.namespace}
              resource={r}
              csv={csv!}
            />
          );
        })}
      </>
    </Row>
  );
}

export default AppListGrid;
